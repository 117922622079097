import React, { useEffect, useState } from 'react';
import './TextBlockControllers.scss';
import { TextBlockInterface } from 'models/WidgetModel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export interface FontFamily {
  label: string;
  value: string;
}

export interface TextBlockProps {
  textBlockData: TextBlockInterface;
  setTextBlockData: any;
  setTextContentIsValid: any;
}

const TextBlockControllers: React.FC<TextBlockProps> = ({ textBlockData, setTextBlockData }) => {
  const [textBlockConfig, setTextBlockConfig] = useState<TextBlockInterface>();
  const [textContent, setTextContent] = useState('');
  const [selectedColor, setSelectedColor] = useState('#000000'); // Stores selected color
  const [counter, setCounter] = useState(1000);
  const reactQuillRef = React.useRef<ReactQuill>(null);

  const checkCharacterCount = (event: any) => {
    if (reactQuillRef && reactQuillRef.current && reactQuillRef.current.unprivilegedEditor) {
      const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
      const characters = unprivilegedEditor.getLength();
      setCounter(1000 - characters);
      if (characters > 1000 && event.key !== 'Backspace') event.preventDefault();
    }
  };

  useEffect(() => {
    setTextBlockConfig(textBlockData);
  }, []);

  useEffect(() => {
    if (textBlockConfig) {
      setTextBlockData(textBlockConfig);
    }
  }, [textBlockConfig]);

  //Effect for API call
  useEffect(() => {
    if (textBlockConfig) {
      textBlockConfig.styles.color = selectedColor;
      setTextBlockConfig({
        ...textBlockConfig,
        textContent: textContent,
      });
    }
  }, [textContent, selectedColor]);

  const modules = {
    toolbar: [
      [{ color: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ align: [] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  // Handle selection changes (detect color change)
  const handleSelectionChange = (range: any) => {
    if (range) {
      const quill = reactQuillRef.current?.getEditor(); // Get the Quill instance
      if (quill) {
        const format = quill.getFormat(range.index); // Get formatting at the selection
        setSelectedColor(format.color || ''); // Update state with selected color
      }
    }
  };

  const formats = ['bold', 'color', 'font', 'italic', 'size', 'underline', 'list', 'align'];

  return textBlockConfig ? (
    <div>
      <div className="diy-input-container">
        <ReactQuill
          theme="snow"
          placeholder={'Enter display text here.'}
          defaultValue={textBlockConfig?.textContent}
          onChange={(value) => setTextContent(value)}
          onChangeSelection={handleSelectionChange}
          modules={modules}
          formats={formats}
          onKeyDown={checkCharacterCount}
          ref={reactQuillRef}
        />
        <div className="character-warning-container">
          {counter <= 0 ? (
            <span className="character-limit-message">You are over the character limit.</span>
          ) : null}
          <span className="character-count" style={{ color: counter <= 0 ? 'red' : '#999999' }}>
            {counter}
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

export default TextBlockControllers;
